import cn from "clsx";
import React, { InputHTMLAttributes } from "react";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  label?: string;
  description?: string;
  placeholder?: string;
  name: string;
  error?: string;
  type?: string;
  shadow?: boolean;
  disableBorderRadius?: boolean;
  variant?: "normal" | "solid" | "outline";
  prefixNode?: React.ReactNode;
  postfixNode?: React.ReactNode;
}
const classes = {
  root: "py-2 px-4 md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-grey-100 text-xs lg:text-sm placeholder:text-grey-60 min-h-12 transition duration-200 ease-in-out",
  normal: "bg-grey-20 border-grey-30 focus:shadow focus:bg-white focus:border-primary",
  solid: "bg-white border-grey-30 focus:outline-none focus:border-grey-80 h-11 md:h-12",
  outline: "border-grey-30 focus:border-primary",
  shadow: "focus:shadow",
};

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className = "block",
      label,
      name,
      error,
      description,
      placeholder,
      variant = "normal",
      shadow = false,
      type = "text",
      disableBorderRadius = false,
      inputClassName,
      labelClassName,
      prefixNode,
      postfixNode,
      ...rest
    },
    ref,
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === "normal",
        [classes.solid]: variant === "solid",
        [classes.outline]: variant === "outline",
        [classes.shadow]: shadow,
        "rounded-md": !disableBorderRadius,
        "!bg-grey-10": variant === "solid" && rest.disabled,
      },
      inputClassName,
    );
    const labelClass = cn(
      "text-grey-90 mb-3 block cursor-pointer text-sm font-semibold leading-none",
      labelClassName,
    );

    return (
      <div className={className}>
        {label && (
          <label htmlFor={name} className={labelClass}>
            {label}
          </label>
        )}
        <label htmlFor={name} className="relative">
          {prefixNode && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-1">{prefixNode}</div>
          )}
          <input
            id={name}
            name={name}
            type={type}
            ref={ref}
            aria-label={name}
            placeholder={placeholder}
            className={rootClassName}
            autoComplete="off"
            spellCheck="false"
            aria-invalid={error ? "true" : "false"}
            {...rest}
          />
          {postfixNode && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-1">{postfixNode}</div>
          )}
        </label>
        {error && (
          <p data-testid={`error-${name}`} className="text-danger my-2 text-xs">
            {error}
          </p>
        )}
        {description && !error && <p className="my-2 text-xs italic">{description}</p>}
      </div>
    );
  },
);

Input.displayName = "Input";
